import React from "react"
import Layout from "../components/Layout"
import "../css/projects/web-project/web_proj1.css"
import { Link } from "gatsby"
import { FaCode, FaLink } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const ml_proj2 = () => {
  return (
    <Layout>
      <SEO
        title="Restotante Con Fusion"
        description="Full Fledged Awesome Website Development (Web-Dev) project"
      />
      <Particle8></Particle8>
      <h6>s</h6>

      <section className="noSelect">
        <Link to="/ml_project/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>

      <section className="proj-name-wrap noSelect">
        <div className="mlproj-img2">
          <div className="py-1-heading">
            <h1>Car Price Prediction Web-App</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Car-Price-Prediction-Web-App"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>
        <div className="git">
          <a
            href="https://car-price-prediction-by-arpit.herokuapp.com/"
            target="_blank"
            rel="noopener noreferrer"
            title="View live"
          >
            <FaLink className="git-btn"></FaLink>
          </a>
        </div>
        
        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It:</h3>
            <p>
              It is a end to end machine learning project
              <br/>
              <br />Model is made on car dataset which include useful information like Year of Purchase, Kilometers Drived etc.
               
              <br />
              Starting with pre-processeing and Feature Engineering of data.
              <br/><br/>
              
              Proceeding with Applying one of the best among Linear Regression, Decision Tree, Random Forest algorithm.

<br/>
              Hyper Parameter Tuning is also performed.
              <br/>
              Finally taking pickle file of our model for later use in Flask. 
               <br/>
              <br/>
              Initialise Flask app, and setting required function's connected with our model.
              <br/> 
              Used good looking template with fine css for our page.
              <br /> <br/>
              Finally deployed on Heroku.
              <br />
            </p>

            <h3>Skills Behind It:</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>Pandas</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Numpy</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>DateTime</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Seaborn</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Pickle</span>
              </button> 
              <button type="button" className="btn-py-1" disabled>
                <span>Sklearn</span>
              </button> 
              <button type="button" className="btn-py-1" disabled>
                <span>Linear Regression</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Decision Tree</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Random Forest</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Flask</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>HTML</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>CSS</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Heroku</span>
              </button>

            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ml_proj2
